import React from 'react';

const Projects = (props) => {
	return (
		<div className="section" id="projects">
			<div className="section-header">
				<h1>Projects</h1>
			</div>
			<div className="timeline">
				<div className="container left">
					<div className="timeline-card">
						<h3><a href="https:inkognitoapp.netlify.com">Inkognito</a></h3>
						<h4>[mobile] [react] [react-native] [express] [socket.io] [graphql]</h4>
						<p>
							Inkognito is the online ecommerce system built to help people browse through products, add them to cart, pay for them electronically and get them delivered anonymously.
							It also features real-time tracking of deliveries.
						</p>
					</div>
				</div>
				<div className="container right">
					<div className="timeline-card">
						<h3><a href="https://mongobid.com">Mongobid</a>{" "}
						<a href="https://play.google.com/store/apps/details?id=com.mongobid">App</a></h3>
						<h4>[mobile] [react] [react-native] [express] [socket.io] [graphql]</h4>
						<p>Mongobid is an penny auction site where users get to place bids on products showcased to win them at very low prices.</p>
					</div>
				</div>
				<div className="container left">
					<div className="timeline-card">
						<h3><a href="https://covers-africa-demo.firebaseapp.com">COVERS</a></h3>
						<h4>[web] [pwa] [react] [graphql]</h4>
						<p>An all-in-one CoronaVirus Emergency Response Solution - COVERS, aimed at providing accurate and timely information to citizens and relevant authorities to fight the pandemic.</p>
					</div>
				</div>
				<div className="container right">
					<div className="timeline-card">
						<h3><a href="https://buzzing.herokuapp.com">Buzzin</a></h3>
						<h4>[web] [node] [express] [ejs]</h4>
						<p>Buzzin is a bus management system intended to be used by companies to schedule pick ups and drop offs for their employees.</p>
					</div>
				</div>
				<div className="container left">
					<div className="timeline-card">
						<h3><a href="https://aladehotels.com">Alade Hotel</a></h3>
						<h4>[web] [php] [laravel]</h4>
						<p>Designed and built the current website for Alade hotels. It also comes with a booking system for the effective management of rooms.</p>
					</div>
				</div>
				<div className="container right">
					<div className="timeline-card">
						<h3><a href="https://damzinium.com">Damzinium</a></h3>
						<h4>[web] [python] [django]</h4>
						<p>Damzinium is an online educational platform where students get access to educational materials written by peers.</p>
					</div>
				</div>
				<div className="container left">
					<div className="timeline-card">
						<h3><a href="https://edennexus.com">Eden Nexus</a></h3>
						<h4>[web] [python] [django]</h4>
						<p>Designed and Built Eden Nexus which is a web application for buying and selling of max products.</p>
					</div>
				</div>
				<div className="container right">
					<div className="timeline-card">
						<h3>COMPSSA Timetable Application</h3>
						<h4>[mobile] [javascript] [react-native]</h4>
						<p>This is an application for viewing schedules for the computer science department. Updates would probably feature an in house chat platform for users.</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Projects;
