import React, { useState, useRef } from 'react';
import {version} from "../../../package.json";

const Contact = (props) => {

	const [name, setName] = useState("");
	const nameRef = useRef();
	const [email, setEmail] = useState("");
	const emailRef = useRef();
	const [message, setMessage] = useState("");
	const messageRef = useRef();

	return(
		<div className="section contact-section" id="contact">
			<div className="section-header">
				<h1>Contact</h1>
				<p className="text-center">
					I'm particularly available to large projects for freelancing opportunities.<br/>
					Should you have any other request or query however, please feel
					free to contact me by clicking on the button below.<br/>
					I can't promise that I will reply soon though.
				</p>
				<p className="text-center">
					<span>nyankahmensah@gmail.com</span><br/>
					<span>+233 (0) 550 123 292</span>
				</p>
			</div>
			{false &&
			<div className="window">
				<div className="window-title-bar">
					<div className="window-title">
						<h3>Contact</h3>
					</div>
					<div className="window-buttons">
						<h3 className="text-red">X</h3>
					</div>
				</div>
				<div className="window-body">
					<p>
						<span className="text-red text-bold">-[<span className="text-primary">guest</span><span>@</span><span className="text-primary">codeine</span>]-[<span className="text-primary">~</span>]<br/>
						--></span><span className="text-primary">$ mail init</span><br/>
						<span className="text-info text-light">mail init v{version}</span><br/>
						<form>
						</form>
							<span className="text-alt">question </span>name (Anonymous):{" "}
							<input
								onKeyDown={(e)=>{
									if(e.key === 'Enter') {setName(e.target.value); emailRef.current.focus()}
							  }}
							  ref={nameRef}
    						type="text"
    						name="name"
    						className="input"
  						/><br/>
							{name &&
								<>
									<span className="text-alt">question </span>email (anon@nyankahmensah.com):{" "}
									<input
										onKeyDown={(e)=>{
											if(e.key === 'Enter') {setEmail(e.target.value); messageRef.current.focus()}
									  }}
										ref={emailRef}
										type="email"
										name="email"
										className="input"
									/><br/>
								</>
							}
							{name && email && message &&
								<>
									<span className="text-alt">question </span>message:{" "}
									<input
										onKeyDown={(e)=>{
											if(e.key === 'Enter') {setMessage(e.target.value); }
									  }}
										ref={messageRef}
										type="text"
										name="message"
										className="input"
									/><br/>
								</>
							}
							<span className="text-alt">success </span>Email sent!<br/>
							<span>Done in 34s.</span>
					</p>
				</div>
				<div className="window-footer">
					<div className="word-count">
						<span>300</span><span>/500 words</span>
					</div>
					<div className="key-status">
						<span>caps</span>
						<span>num</span>
					</div>
				</div>
			</div>
			}
		</div>
	);
}

export default Contact;
