import React from 'react';

const Experience = (props) => {
	return(
		<>
			<div className="section" id="experience">
				<div className="section-header">
					<h1>Professional Experience</h1>
				</div>
				<div className="timeline">
					<div className="container left">
						<div className="timeline-card">
							<h3>July 2020 - Present</h3>
							<h3>Software Developer, <a href="https://polymorphlabs.io" rel="noreferrer">Polymorph Labs</a></h3>
						</div>
					</div>
					<div className="container right">
						<div className="timeline-card">
							<h3>July 2020 - Present</h3>
							<h3>Software Developer, <a href="https://mongobid.com" rel="noreferrer">Mongobid Technologies</a></h3>
						</div>
					</div>
					<div className="container left">
						<div className="timeline-card">
							<h3>July 2019 - August 2019</h3>
							<h3>IT Support Intern, <a href="https://www.ghanaports.gov.gh/" rel="noreferrer">Ghana Ports and Harbour Authority</a></h3>
						</div>
					</div>
					<div className="container right">
						<div className="timeline-card">
							<h3>March 2019 - Present</h3>
							<h3>Full-Stack Developer, <a href="https://www.damzinium.com/" rel="noreferrer">Damzinium Inc</a></h3>
						</div>
					</div>
					<div className="container left">
						<div className="timeline-card">
							<h3>January 2018 - Present</h3>
							<h3>Chief Technology Officer, BackSlash Technologies</h3>
						</div>
					</div>
				</div>
			</div>
			<div className="section" id="other-experiences">
				<div className="section-header">
					<h1>Other Experiences</h1>
				</div>
				<div className="timeline">
					<div className="container left">
						<div className="timeline-card">
							<h3>September 2019 - Present</h3>
							<h3>Vice President, Computer Science Students' Association, UG</h3>
						</div>
					</div>
					<div className="container right">
						<div className="timeline-card">
							<h3>September 2019 - Present</h3>
							<h3>Campus Ambassador, <a href="https://developersinvogue.org/" rel="noreferrer noopener">Developers In Vogue</a></h3>
						</div>
					</div>
					<div className="container left">
						<div className="timeline-card">
							<h3>March 2019 - Present</h3>
							<h3>Community Lead, <a href="https://twitter.com/girlstoocode" rel="noreferrer noopener">GirlsTooCode</a></h3>
						</div>
					</div>
					<div className="container right">
						<div className="timeline-card">
							<h3>November 2018</h3>
							<h3>Participant, National Sanitation Hackathon</h3>
						</div>
					</div>
					<div className="container left">
						<div className="timeline-card">
							<h3>September 2015 - May 2016</h3>
							<h3>President, Ghana Secondary Technical School's Science And Technology Club</h3>
						</div>
					</div>
					<div className="container right">
						<div className="timeline-card">
							<h3>October 2015</h3>
							<h3>Participant, <a href="https://foundation.ghanarobotics.org" rel="noreferrer noopener">GRAF RISE Competition 2015</a></h3>
						</div>
					</div>
					<div className="container left">
						<div className="timeline-card">
							<h3>September 2014 - July 2015</h3>
							<h3>President, Ghana Secondary Technical School's Robotics Club</h3>
						</div>
					</div>
					<div className="container right">
						<div className="timeline-card">
							<h3>October 2014</h3>
							<h3>Participant, <a href="https://foundation.ghanarobotics.org" rel="noreferrer noopener">GRAF RISE Competition 2014</a></h3>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Experience;
