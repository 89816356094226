import React from 'react';
import './assets/css/style.css';
import './assets/css/icon.css';

import {
	Header,
	Footer,
	Technology,
	Contact,
	Projects,
	Skills,
	Education,
	Experience
} from './components';

const Portfolio = () => {
  return (
    <div className="curtain">
    	<Header />
			<div className="content">
				<div className="section intro" id="about" style={{backgroundImage: `url(${require("./assets/images/profile-new.jpg")})`}}>
					<div className="section-header">
						<div className="hide">
							<h1>Hello, I"m Nyankah Mensah.</h1>
							<h3>
								I spend most of my time as a backend developer writing clean, elegant and efficient code which no one gets to see {"(⌣́_⌣̀)"}
							</h3>
						</div>
					</div>
				</div>
				<Skills />
				<Experience />
				<Technology />
				<Projects />
				<Education />
				<Contact />
				<div className="section footer-section">
					<div>
						<span className="text-bold">© </span>{new Date().getFullYear()}<span className="text-bold"> Nyankah Mensah </span>All rights reserved
					</div>
					<div>
						Designed by <span className="text-bold"><a href="https://github.com/devDeclan" rel="noopener noreferrer">devDeclan</a></span>
					</div>
				</div>
			</div>
			<Footer />
		</div>
    );
}

export default Portfolio;
